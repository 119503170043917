import React, {useContext, useState} from 'react'
import {
    MoonIcon,
    SunIcon,
    BellIcon,
    OutlinePersonIcon,
    OutlineCogIcon,
    OutlineLogoutIcon, MenuIcon,
} from '../../icons'

import {useHistory, withRouter} from 'react-router-dom'
import {SidebarContext} from "../../context/SidebarContext";

function HamburgerMenu() {
    const { toggleSidebar } = useContext(SidebarContext)

    return (
        <>
            <button
                className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-primary"
                onClick={toggleSidebar}
                aria-label="Menu"
            >
                <MenuIcon className="w-6 h-6" aria-hidden="true" />
            </button>
        </>
    )
}

export default withRouter(HamburgerMenu)
