import React, {useCallback, useEffect, useState} from 'react'
import {
    Input,
    Label,
    HelperText
} from '@windmill/react-ui'

import {withRouter} from 'react-router-dom'
import {useStoreState} from "easy-peasy";

import {BsPencil} from "react-icons/bs";

import debounce from "lodash/debounce";
import {useLoadProjectQuery} from "../../hooks/useLoadProjectQuery";

import {
    useMutation,
} from 'react-query'
import {changeProjectLabel, projectIcons} from "../../helpers/projectHelpers";
import SecondaryMenu from "./SecondaryMenu";
import HamburgerMenu from "./HamburgerMenu";
import Logo from "./Logo";
import Icons from "./Icons";


function ProjectHeader() {
    const activeProjectId = useStoreState((state) => state.activeProjectId);
    const [isLabelSaved, setIsLabelSaved] = useState(false);
    const [isLabelValid, setIsLabelValid] = useState(true);
    const [icons, setIcons] = useState(projectIcons);

    const {
        isLoading: projectIsLoading,
        isFetching: projectIsFetching,
        error: projectError,
        data: projectData
    } = useLoadProjectQuery(activeProjectId);

    const changeProjectLabelMutation = useMutation((payload) => {
        return changeProjectLabel(payload);
    }, {
        onSuccess: (response) => {
            // met timeout
            setIsLabelSaved(true);
            setTimeout(() => {
                setIsLabelSaved(false);
            }, 5000)
        },
    })

    const handleProjectLabelChange = (label, id) => {
        setIsLabelSaved(false);
        if (label.length < 3 || label.length > 200) {
            setIsLabelValid(false)

        } else {
            setIsLabelValid(true)
            changeProjectLabelMutation.mutate({id, label})
        }
    }


    const debounceProjectLabelChange = useCallback(debounce(handleProjectLabelChange, 400), []);

    useEffect(() => {
            let icons = {...projectIcons}

            Object.keys(icons).map((key) => {

                if (key == "project_info") {
                    icons[key].label = projectData?.label;
                } else if (key == "specifications") {
                    icons[key].disabled = (!projectData?.articles || (projectData?.articles && !projectData.articles.length));
                } else if (key == "prices") {
                    icons[key].disabled = (!projectData?.has_measurements);
                }
            });
            setIcons(icons);
        }
        , [projectData, projectIcons])

    return (

        <Icons icons={icons} id={activeProjectId}/>


    )
}

export default withRouter(ProjectHeader)
