import React, {useContext} from 'react'

import CalqiLogoFull from '../../assets/img/calqi_logo_full.svg'
import {NavLink} from "react-router-dom";

function Logo() {
    return (
        <NavLink to={"/app"} className={"relative z-40"}>
            <img src={CalqiLogoFull} className={"w-24"}/>
            <div className={"text-xs text-primary-400 mt-1 absolute"}
             style={{
                top: "27px",
                left: "34px"
            }}>
                beta
            </div>
        </NavLink>
    )
}

export default Logo
