import React, {useContext, useState} from 'react'
import {t} from '../../helpers/helpers';
import {
    MoonIcon,
    SunIcon,
    BellIcon,
    OutlinePersonIcon,
    OutlineCogIcon,
    OutlineLogoutIcon,
} from '../../icons'
import {
    Avatar,
    Badge,
    Dropdown,
    DropdownItem,
    WindmillContext,
} from '@windmill/react-ui'

import {useHistory, withRouter} from 'react-router-dom'
import { logout } from "../../helpers/authenticationHelpers";
import UserAvatar from "../KokoComponents/User/UserAvatar";

function SecondaryMenu({guest = false}) {
    const { mode, toggleMode } = useContext(WindmillContext)

    const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false)
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)

    const history = useHistory();

    function handleNotificationsClick() {
        setIsNotificationsMenuOpen(!isNotificationsMenuOpen)
    }

    function handleProfileClick() {

        setIsProfileMenuOpen(!isProfileMenuOpen)
    }

    function news() {
        history.push("/app/news");
    }
    function settings() {
        history.push("/app/settings");
    }

    function profile() {
        history.push("/app/profile");
    }


    return (
        <>
                <ul className="flex items-center flex-shrink-0 space-x-6">
                    {(true == false &&
                    /* <!-- Theme toggler --> */


                        <li className="flex">
                            <button
                                className="rounded-md focus:outline-none focus:shadow-outline-primary"
                                onClick={toggleMode}
                                aria-label="Toggle color mode"
                            >
                                {mode === 'dark' ? (
                                    <SunIcon className="w-5 h-5" aria-hidden="true" />
                                ) : (
                                    <MoonIcon className="w-5 h-5" aria-hidden="true" />
                                )}
                            </button>
                        </li>

                        )}

                    {(true == false &&
                        /* <!-- Notifications menu --> */
                    <li className="relative">
                        <button
                            className="relative align-middle rounded-md focus:outline-none focus:shadow-outline-primary"
                            onClick={handleNotificationsClick}
                            aria-label="Notifications"
                            aria-haspopup="true"
                        >
                            <BellIcon className="w-5 h-5" aria-hidden="true" />
                            {/* <!-- Notification badge --> */}
                            <span
                                aria-hidden="true"
                                className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"
                            ></span>
                        </button>

                        <Dropdown
                            align="right"
                            isOpen={isNotificationsMenuOpen}
                            onClose={() => setIsNotificationsMenuOpen(false)}
                        >
                            <DropdownItem tag="button" href="#" className="justify-between">
                                <span>Meldingen</span>
                                <Badge type="danger">3</Badge>
                            </DropdownItem>
                        </Dropdown>
                    </li>)}
                    {/* <!-- Profile menu --> */}
                    <li className="relative">
                        <button
                            className="rounded-full focus:shadow-outline-primary focus:outline-none"
                            onClick={handleProfileClick}
                            aria-label="Account"
                            aria-haspopup="true"
                        >
                           <UserAvatar />
                        </button>
                        <Dropdown
                            align="right"
                            isOpen={isProfileMenuOpen}
                            /*onClose={() => setIsProfileMenuOpen(false)}*/
                        >
                            <DropdownItem tag="button" onClick={() => profile(history)}>
                                <OutlinePersonIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                                <span>Profiel</span>
                            </DropdownItem>
                            {
                                !guest &&
                                    <>
                                        <DropdownItem tag="button" onClick={() => settings(history)}>
                                            <OutlineCogIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                                            <span>{t("settings_title")}</span>
                                        </DropdownItem>
                                        <DropdownItem tag="button" onClick={() => news(history)}>
                                            <OutlineCogIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                                            <span>{t("news")}</span>
                                        </DropdownItem>
                                    </>
                            }

                            <DropdownItem onClick={() => logout(history)}>
                                <OutlineLogoutIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                                <span>Uitloggen</span>
                            </DropdownItem>
                        </Dropdown>
                    </li>
                </ul>
        </>
    )
}

export default withRouter(SecondaryMenu)
