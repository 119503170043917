import React, {useState, useEffect} from "react";

import {NavLink, useLocation} from "react-router-dom";
import Loader from "../Loader";

import {useStoreActions, useStoreState} from "easy-peasy";
import {Avatar} from "@windmill/react-ui";

const UserAvatar = () => {


    const loadProfile = useStoreActions((actions) => actions.loadProfile);
    const data = useStoreState((state) => state.user);

    useEffect(() => {
            loadProfile();
        }
        , []);



    return (
        <>

            {data && 'id' in data && data.id !== 0 ?
                <div className={"flex justify-start space-x-2 items-center"}>
                    <Avatar
                        className="align-middle bg-primary"
                        src={data.avatar}
                        alt=""
                        aria-hidden="true"
                    />

                    <div>
                        <div className={"font-bold leading-none"}>
                            {(data.firstName && data.firstName )}
                        </div>
                        <div className={"leading-none"}>
                            {(data.organisation && data.organisation.label )}
                        </div>
                    </div>


                </div>
                :
                <Loader/>
            }

        </>
    )
}

export default UserAvatar;